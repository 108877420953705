import Constants from '../Constants';

export const SHIPPING_LINES_FETCHED = 'SHIPPING_LINES_FETCHED';
export const SHIPPING_LINE_ADDED = 'SHIPPING_LINE_ADDED';
export const SHIPPING_LINE_UPDATED = 'SHIPPING_LINE_UPDATED';
export const SHIPPING_LINES_REMOVED = 'SHIPPING_LINES_REMOVED';
export const SHIPPING_LINES_ERROR = 'SHIPPING_LINES_ERROR';

const BASE_URL = `${Constants.URL}shipping_lines`;

export function loadShippingLines(shippingLines) {
  return {
    type: SHIPPING_LINES_FETCHED,
    payload: shippingLines,
  };
}

export function addShippingLine(shippingLine) {
  return {
    type: SHIPPING_LINE_ADDED,
    payload: shippingLine,
  };
}

export function removeShippingLine(shippingLine) {
  return {
    type: SHIPPING_LINES_REMOVED,
    payload: shippingLine,
  };
}

export function updateShippingLine(shippingLine) {
  return {
    type: SHIPPING_LINE_UPDATED,
    payload: shippingLine,
  };
}

export function errorShippingLine(error) {
  return {
    type: SHIPPING_LINES_ERROR,
    payload: error,
  };
}

export function fetchShippingLines(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorShippingLine))
      .then(json => dispatch(loadShippingLines(json)))
      .catch(error => dispatch(errorShippingLine(error)));
  };
}

export function postShippingLine(shippingLine, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(shippingLine),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorShippingLine))
      .then(json => dispatch(addShippingLine(json)))
      .catch(error => dispatch(errorShippingLine(error)));
  };
}

export function patchShippingLine(shippingLine, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${shippingLine.id}`, {
      method: 'PATCH',
      body: JSON.stringify(shippingLine),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorShippingLine))
      .then(json => {
        const thisJson = JSON.parse(JSON.stringify(json));
        dispatch(updateShippingLine(thisJson));
      })
      .catch(error => dispatch(errorShippingLine(error)));
  };
}

export function deleteShippingLine(shippingLine, token) {
  return dispatch => {
    const copyShippingLine = JSON.parse(JSON.stringify(shippingLine));
    delete copyShippingLine.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${shippingLine.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorShippingLine))
      .then(() => dispatch(removeShippingLine(copyShippingLine)))
      .catch(error => dispatch(errorShippingLine(error)));
  };
}
