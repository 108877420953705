import Constants from '../Constants';

export const VOYAGE_LEGS_FETCHED = 'VOYAGE_LEGS_FETCHED';
export const VOYAGE_LEG_ADDED = 'VOYAGE_LEG_ADDED';
export const VOYAGE_LEG_UPDATED = 'VOYAGE_LEG_UPDATED';
export const VOYAGE_LEG_REMOVED = 'VOYAGE_LEG_REMOVED';
export const VOYAGE_LEG_ERROR = 'VOYAGE_LEG_ERROR';

export const VL_BASE_URL = `${Constants.URL}voyage_legs`;

export function loadVoyageLegs(voyageLegs) {
  return {
    type: VOYAGE_LEGS_FETCHED,
    payload: voyageLegs,
  };
}

export function addVoyageLeg(voyageLeg) {
  return {
    type: VOYAGE_LEG_ADDED,
    payload: voyageLeg,
  };
}

export function removeVoyageLeg(voyageLeg) {
  return {
    type: VOYAGE_LEG_REMOVED,
    payload: voyageLeg,
  };
}

export function updateVoyageLeg(voyageLeg) {
  return {
    type: VOYAGE_LEG_UPDATED,
    payload: voyageLeg,
  };
}

export function errorVoyageLegs(error) {
  return {
    type: VOYAGE_LEG_ERROR,
    payload: error,
  };
}

export function fetchVoyageLegs(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(VL_BASE_URL, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorVoyageLegs))
      .then(json => dispatch(loadVoyageLegs(json)))
      .catch(error => dispatch(errorVoyageLegs(error)));
  };
}

export function postVoyageLeg(voyageLeg, token) {
  const payload = {
    vessel_name: voyageLeg.vessel_name,
    voyage_number: voyageLeg.voyage_number,
    shipping_line_id: voyageLeg.shipping_line_id,
    origin_port_id: voyageLeg.origin_port_id,
    destination_port_id: voyageLeg.destination_port_id,
    etd: voyageLeg.etd,
    eta: voyageLeg.eta,
  };

  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(VL_BASE_URL, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorVoyageLegs))
      .then(json => dispatch(addVoyageLeg(json)))
      .catch(error => dispatch(errorVoyageLegs(error)));
  };
}

export function patchVoyageLeg(voyageLeg, token) {
  const payload = {
    vessel_name: voyageLeg.vessel_name,
    voyage_number: voyageLeg.voyage_number,
    shipping_line_id: voyageLeg.shipping_line_id,
    origin_port_id: voyageLeg.origin_port_id,
    destination_port_id: voyageLeg.destination_port_id,
    etd: voyageLeg.etd,
    eta: voyageLeg.eta,
  };

  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${VL_BASE_URL}/${voyageLeg.id}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorVoyageLegs))
      .then(json => {
        const thisJson = JSON.parse(JSON.stringify(json));
        dispatch(updateVoyageLeg(thisJson));
      })
      .catch(error => dispatch(errorVoyageLegs(error)));
  };
}

export function deleteVoyageLeg(voyageLeg, token) {
  return dispatch => {
    const copyVoyageLeg = JSON.parse(JSON.stringify(voyageLeg));
    delete copyVoyageLeg.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${VL_BASE_URL}/${voyageLeg.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorVoyageLegs))
      .then(() => dispatch(removeVoyageLeg(copyVoyageLeg)))
      .catch(error => dispatch(errorVoyageLegs(error)));
  };
}
