import Constants from '../Constants';
import { errorPackerPlant } from './actions_packer_plants';

export const SUPPORT_TICKET_ADDED = 'SUPPORT_TICKET_ADDED';
export const SUPPORT_TICKET_UPDATED = 'SUPPORT_TICKET_UPDATED';
export const SUPPORT_TICKETS_FETCHED = 'SUPPORT_TICKETS_FETCHED';
export const SUPPORT_EMAIL_ADDED = 'SUPPORT_EMAIL_ADDED';
export const SUPPORT_EMAIL_UPDATED = 'SUPPORT_EMAIL_UPDATED';
export const SUPPORT_EMAIL_DELETED = 'SUPPORT_EMAIL_DELETED';
export const SUPPORT_EMAILS_FETCHED = 'SUPPORT_EMAILS_FETCHED';
export const SUPPORT_ERROR = 'SUPPORT_ERROR';

export function ticketAdded(ticket) {
  return {
    type: SUPPORT_TICKET_ADDED,
    payload: ticket,
  };
}

export function ticketsFetched(tickets) {
  return {
    type: SUPPORT_TICKETS_FETCHED,
    payload: tickets,
  };
}

export function ticketUpdated(ticket) {
  return {
    type: SUPPORT_TICKET_UPDATED,
    payload: ticket,
  };
}

export function supportEmailAdded(supportEmail) {
  return {
    type: SUPPORT_EMAIL_ADDED,
    payload: supportEmail,
  };
}

export function supportEmailUpdated(supportEmail) {
  return {
    type: SUPPORT_EMAIL_UPDATED,
    payload: supportEmail,
  };
}

export function supportEmailDeleted(supportEmailId) {
  return {
    type: SUPPORT_EMAIL_DELETED,
    payload: supportEmailId,
  };
}

export function supportEmailsFetched(supportEmails) {
  return {
    type: SUPPORT_EMAILS_FETCHED,
    payload: supportEmails,
  };
}

export function errorTicket(error) {
  return {
    type: SUPPORT_ERROR,
    payload: error,
  };
}

export function postSupportTicket(supportTicket, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}tickets`, {
      method: 'POST',
      body: JSON.stringify(supportTicket),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTicket))
      .then(json => dispatch(ticketAdded(json)))
      .catch(error => dispatch(errorTicket(error)));
  };
}

export function patchSupportTicket(supportTicket, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}tickets/${supportTicket.id}`, {
      method: 'PATCH',
      body: JSON.stringify(supportTicket),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTicket))
      .then(json => dispatch(ticketUpdated(json)))
      .catch(error => dispatch(errorTicket(error)));
  };
}

export function getSupportTickets(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}tickets`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTicket))
      .then(json => dispatch(ticketsFetched(json)))
      .catch(error => dispatch(errorTicket(error)));
  };
}

export function getSupportEmails(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}support_emails`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTicket))
      .then(json => dispatch(supportEmailsFetched(json)))
      .catch(error => dispatch(errorTicket(error)));
  };
}

export function postSupportEmail(supportEmail, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}support_emails`, {
      method: 'POST',
      body: JSON.stringify(supportEmail),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTicket))
      .then(json => dispatch(supportEmailAdded(json)))
      .catch(error => dispatch(errorTicket(error)));
  };
}

export function patchSupportEmail(supportEmail, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}support_emails/${supportEmail.id}`, {
      method: 'PATCH',
      body: JSON.stringify(supportEmail),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorTicket))
      .then(json => dispatch(supportEmailUpdated(json)))
      .catch(error => dispatch(errorTicket(error)));
  };
}

export function deleteSupportEmail(supportEmail, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}support_emails/${supportEmail.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorPackerPlant))
      .then(() => dispatch(supportEmailDeleted(supportEmail)))
      .catch(error => dispatch(errorPackerPlant(error)));
  };
}
