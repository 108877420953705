import Constants from '../Constants';

export const SPECIFICATIONS_FETCHED = 'SPECIFICATIONS_FETCHED';
export const SPECIFICATIONS_ADDED = 'SPECIFICATIONS_ADDED';
export const SPECIFICATIONS_UPDATED = 'SPECIFICATIONS_UPDATED';
export const SPECIFICATIONS_REMOVED = 'SPECIFICATIONS_REMOVED';
export const SPECIFICATIONS_ERROR = 'SPECIFICATIONS_ERROR';

const BASE_URL = `${Constants.URL}specifications`;

export function loadSpecifications(specifications) {
  return {
    type: SPECIFICATIONS_FETCHED,
    payload: specifications,
  };
}

export function addSpecifications(specifications) {
  return {
    type: SPECIFICATIONS_ADDED,
    payload: specifications,
  };
}

export function removeSpecifications(specifications) {
  return {
    type: SPECIFICATIONS_REMOVED,
    payload: specifications,
  };
}

export function updateSpecifications(specifications) {
  return {
    type: SPECIFICATIONS_UPDATED,
    payload: specifications,
  };
}

export function errorSpecifications(error) {
  return {
    type: SPECIFICATIONS_ERROR,
    payload: error,
  };
}

export function fetchSpecifications(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorSpecifications))
      .then(json => dispatch(loadSpecifications(json)))
      .catch(error => dispatch(errorSpecifications(error)));
  };
}

export function postSpecifications(specifications, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(specifications),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorSpecifications))
      .then(json => dispatch(addSpecifications(json)))
      .catch(error => dispatch(errorSpecifications(error)));
  };
}

export function patchSpecifications(specifications, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${specifications.uid}`, {
      method: 'PATCH',
      body: JSON.stringify(specifications),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorSpecifications))
      .then(json => {
        const thisJson = JSON.parse(JSON.stringify(json));
        dispatch(updateSpecifications(thisJson));
      })
      .catch(error => dispatch(errorSpecifications(error)));
  };
}

export function deleteSpecifications(specifications, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${specifications.uid}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorSpecifications))
      .then(() => dispatch(removeSpecifications(specifications)))
      .catch(error => dispatch(errorSpecifications(error)));
  };
}
