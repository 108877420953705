import api from '../api';
import Constants from '../Constants';

export const TRANSPORT_FETCHED = 'ROLES_FETCHED';
export const TRANSPORT_EDIT = 'TRANSPORT_EDIT';
export const TRANSPORT_ERROR = 'ROLE_ERROR';
export const CHANGE_TRANSPORT_MODAL = 'CHANGE_TRANSPORT_MODAL';
export const SET_TRANSPORT_LOADING = 'SET_TRANSPORT_LOADING';
export const TRANSPORT_CREATED = 'TRANSPORT_CREATED';
export const TRANSPORT_DELETED = 'TRANSPORT_DELETED';

const basePath = '/transport';

export function loadTransport(transport) {
  return {
    type: TRANSPORT_FETCHED,
    payload: transport,
  };
}

export function transportCreated(transport) {
  return {
    type: TRANSPORT_CREATED,
    payload: transport,
  };
}

export function transportDeleted(transport) {
  return {
    type: TRANSPORT_DELETED,
    payload: transport,
  };
}

export function editTransport(transport) {
  return {
    type: TRANSPORT_EDIT,
    payload: transport,
  };
}

export function setTransportModalStatus(isOpen) {
  return {
    type: CHANGE_TRANSPORT_MODAL,
    payload: isOpen,
  };
}

export function setTransportLoading(isLoading) {
  return {
    type: SET_TRANSPORT_LOADING,
    payload: isLoading,
  };
}

export function errorTransport(error) {
  return {
    type: TRANSPORT_ERROR,
    payload: error,
  };
}

export const fetchAllTransport = () => dispatch => {
  return api
    .get(basePath)
    .then(response => Constants.handleErrors(response, dispatch, errorTransport))
    .then(json => dispatch(loadTransport(json)))
    .catch(error => dispatch(errorTransport(error)));
};

export const saveTransport = payload => dispatch => {
  return api
    .post(basePath, payload)
    .then(response => {
      dispatch(transportCreated(response.data));
      dispatch(setTransportModalStatus(false));
    })
    .then(response => Constants.handleErrors(response, dispatch, errorTransport))
    .catch(error => dispatch(errorTransport(error)))
    .finally(() => {
      dispatch(setTransportLoading(false));
    });
};

export const deleteTransport = item => dispatch => {
  return api
    .delete(`${basePath}/packers/${item.packer_plant_id}/grinders/${item.grinder_uid}`)
    .then(response => Constants.handleErrors(response, dispatch, errorTransport))
    .then(json => dispatch(transportDeleted(json)))
    .catch(error => dispatch(errorTransport(error)));
};

export const fetchTransport = (packerID, grinderID) => dispatch => {
  const urlByPacker = `${basePath}/packers/${packerID}`;
  const url = grinderID ? `${urlByPacker}/grinders/${grinderID}` : urlByPacker;

  return api
    .get(url)
    .then(response => Constants.handleErrors(response, dispatch, errorTransport))
    .then(json => dispatch(loadTransport(json)))
    .catch(error => dispatch(errorTransport(error)));
};

export const patchTransport = transport => dispatch => {
  const url = `${basePath}/packers/${transport.packer_plant_id}/grinders/${transport.grinder_uid}`;

  return api
    .patch(url, transport)
    .then(response => Constants.handleErrors(response, dispatch, errorTransport))
    .then(json => dispatch(editTransport(json)))
    .catch(error => dispatch(errorTransport(error)));
};
