import Constants from '../Constants';

export const PRODUCTION_PLANNER_FETCHED = 'PRODUCTION_PLANNER_FETCHED';
export const PRODUCTION_PLANNER_EDITED = 'PRODUCTION_PLANNER_EDITED';
export const PRODUCTION_PLANNER_ERROR = 'PRODUCTION_PLANNER_ERROR';

export function editProductionPlanner(forecast) {
  return {
    type: PRODUCTION_PLANNER_EDITED,
    payload: forecast,
  };
}

export function loadProductionPlanner(forecasts) {
  return {
    type: PRODUCTION_PLANNER_FETCHED,
    payload: forecasts,
  };
}

export function errorProductionPlanner(error) {
  return {
    type: PRODUCTION_PLANNER_ERROR,
    payload: error,
  };
}

export function fetchProductionPlanner(grinder, beef_product, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${Constants.URL}planner/grinders/${grinder.uid}/beef_patties/${beef_product.uid}`, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorProductionPlanner))
      .then(json => {
        if (json.length === 0) {
          json.push('none');
        }
        dispatch(loadProductionPlanner(json));
      })
      .catch(error => dispatch(errorProductionPlanner(error)));
  };
}

export function postProductionPlanner(grinder, beef_product, quantity, token) {
  return dispatch => {
    const edited_beef_product = JSON.parse(JSON.stringify(beef_product));
    edited_beef_product.quantity = parseInt(quantity, 10);
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(
      `${Constants.URL}planner/grinders/${grinder.uid}/beef_patties/${edited_beef_product.output_product_uid}`,
      {
        method: 'POST',
        body: JSON.stringify(edited_beef_product),
        headers: header,
      }
    )
      .then(response => Constants.handleErrors(response, dispatch, errorProductionPlanner))
      .then(json => dispatch(editProductionPlanner(json)))
      .catch(error => dispatch(errorProductionPlanner(error)));
  };
}
