import Constants from '../Constants';

export const UOM_FETCHED = 'UOM_FETCHED';
export const UOM_ADDED = 'UOM_ADDED';
export const UOM_UPDATED = 'UOM_UPDATED';
export const UOM_REMOVED = 'UOM_REMOVED';
export const UOM_ERROR = 'UOM_ERROR';

const BASE_URL = `${Constants.URL}units`;

export function loadUnitsOfMeasures(unitsOfMeasure) {
  return {
    type: UOM_FETCHED,
    payload: unitsOfMeasure,
  };
}

export function addUnitOfMeasure(unitOfMeasure) {
  return {
    type: UOM_ADDED,
    payload: unitOfMeasure,
  };
}

export function removeUnitOfMeasure(unitOfMeasure) {
  return {
    type: UOM_REMOVED,
    payload: unitOfMeasure,
  };
}

export function updateUnitOfMeasure(unitOfMeasure) {
  return {
    type: UOM_UPDATED,
    payload: unitOfMeasure,
  };
}

export function errorUnitOfMeasure(error) {
  return {
    type: UOM_ERROR,
    payload: error,
  };
}

export function fetchUnitsOfMeasure(token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'GET',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorUnitOfMeasure))
      .then(json => dispatch(loadUnitsOfMeasures(json)))
      .catch(error => dispatch(errorUnitOfMeasure(error)));
  };
}

export function postUnitOfMeasure(unitOfMeasure, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(BASE_URL, {
      method: 'POST',
      body: JSON.stringify(unitOfMeasure),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorUnitOfMeasure))
      .then(json => dispatch(addUnitOfMeasure(json)))
      .catch(error => dispatch(errorUnitOfMeasure(error)));
  };
}

export function patchUnitOfMeasure(unitOfMeasure, token) {
  return dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${unitOfMeasure.id}`, {
      method: 'PATCH',
      body: JSON.stringify(unitOfMeasure),
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorUnitOfMeasure))
      .then(json => {
        const thisJson = JSON.parse(JSON.stringify(json));
        dispatch(updateUnitOfMeasure(thisJson));
      })
      .catch(error => dispatch(errorUnitOfMeasure(error)));
  };
}

export function deleteUnitOfMeasure(unitOfMeasure, token) {
  return dispatch => {
    const copyUnitOfMeasure = JSON.parse(JSON.stringify(unitOfMeasure));
    delete copyUnitOfMeasure.type;
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });
    return fetch(`${BASE_URL}/${unitOfMeasure.id}`, {
      method: 'DELETE',
      headers: header,
    })
      .then(response => Constants.handleErrors(response, dispatch, errorUnitOfMeasure))
      .then(() => dispatch(removeUnitOfMeasure(copyUnitOfMeasure)))
      .catch(error => dispatch(errorUnitOfMeasure(error)));
  };
}
