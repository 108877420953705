import Constants from '../Constants';

export const ADD_NCR_CHAT_SUCCESS = 'ADD_NCR_CHAT_SUCCESS';
export const ADD_NCR_CHAT_ERROR = 'ADD_NCR_CHAT_ERROR';
export const RESET_NCR_CHAT_CHANNELS = 'RESET_NCR_CHAT_CHANNELS';
export const FETCH_NCR_CHAT_CHANNELS_SUCCESS = 'FETCH_NCR_CHAT_CHANNELS_SUCCESS';
export const FETCH_NCR_CHAT_CHANNELS_ERROR = 'FETCH_NCR_CHAT_CHANNELS_ERROR';
export const SEND_NCR_CHAT_MESSAGE_SUCCESS = 'SEND_NCR_CHAT_MESSAGE_SUCCESS';
export const SEND_NCR_CHAT_MESSAGE_ERROR = 'SEND_NCR_CHAT_MESSAGE_ERROR';
export const RESET_NCR_CHAT_CHANNEL_MESSAGES = 'RESET_NCR_CHAT_CHANNEL_MESSAGES';
export const FETCH_NCR_CHAT_CHANNEL_MESSAGES_SUCCESS = 'FETCH_NCR_CHAT_CHANNEL_MESSAGES_SUCCESS';
export const FETCH_NCR_CHAT_CHANNEL_MESSAGES_ERROR = 'FETCH_NCR_CHAT_CHANNEL_MESSAGES_ERROR';
export const FETCH_NCR_UNREAD_MESSAGES_SUCCESS = 'FETCH_NCR_UNREAD_MESSAGES_SUCCESS';
export const FETCH_NCR_UNREAD_MESSAGES_ERROR = 'FETCH_NCR_UNREAD_MESSAGES_ERROR';
export const FETCH_NCR_UNREAD_MESSAGES_RESET = 'FETCH_NCR_UNREAD_MESSAGES_ERROR';

export function addNCRChatSuccess() {
  return {
    type: ADD_NCR_CHAT_SUCCESS,
  };
}

export function addNCRChatError() {
  return {
    type: ADD_NCR_CHAT_ERROR,
  };
}

export function resetNCRChatChannels() {
  return {
    type: RESET_NCR_CHAT_CHANNELS,
  };
}
export function fetchNCRChatChannelsSuccess(channels) {
  return {
    type: FETCH_NCR_CHAT_CHANNELS_SUCCESS,
    payload: channels,
  };
}

export function fetchNCRChatChannelsError() {
  return {
    type: FETCH_NCR_CHAT_CHANNELS_ERROR,
  };
}

export function sendNCRChatMessageSuccess() {
  return {
    type: SEND_NCR_CHAT_MESSAGE_SUCCESS,
  };
}

export function sendNCRChatMessageError(error) {
  return {
    type: SEND_NCR_CHAT_MESSAGE_ERROR,
    payload: error,
  };
}

export function resetNCRChatChannelMessages() {
  return {
    type: RESET_NCR_CHAT_CHANNEL_MESSAGES,
  };
}
export function fetchNCRChatChannelMessagesSuccess(messages) {
  return {
    type: FETCH_NCR_CHAT_CHANNEL_MESSAGES_SUCCESS,
    payload: messages,
  };
}

export function fetchNCRChatChannelMessagesError() {
  return {
    type: FETCH_NCR_CHAT_CHANNEL_MESSAGES_ERROR,
  };
}

export function resetNCRChatUnreadMessages() {
  return {
    type: FETCH_NCR_UNREAD_MESSAGES_RESET,
  };
}

export function fetchNCRChatUnreadMessagesSuccess(messages) {
  return {
    type: FETCH_NCR_UNREAD_MESSAGES_SUCCESS,
    payload: messages,
  };
}

export function fetchNCRChatUnreadMessagesError() {
  return {
    type: FETCH_NCR_UNREAD_MESSAGES_ERROR,
  };
}

export function fetchNCRChatChannels(recordNo, token) {
  return async dispatch => {
    dispatch(resetNCRChatChannels());
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    try {
      const response = await fetch(`${Constants.URL}grinder_ncr/${recordNo}/chats`, {
        method: 'GET',
        headers: header,
      });
      const channels = await Constants.handleErrors(response, dispatch, fetchNCRChatChannelsError);
      dispatch(fetchNCRChatChannelsSuccess(channels));
    } catch (error) {
      dispatch(fetchNCRChatChannelsError(error));
    }
    return null;
  };
}

export function addNCRChat(payload, token) {
  return async dispatch => {
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    try {
      const response = await fetch(`${Constants.URL}grinder_ncr/${payload.record_no}/chats`, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(payload),
      });
      await Constants.handleErrors(response, dispatch, addNCRChatError);
      dispatch(fetchNCRChatChannels(payload.record_no));
    } catch (error) {
      dispatch(addNCRChatError(error));
    }
    return null;
  };
}

export function fetchNCRChatChannelMessages(channelId, token) {
  return async dispatch => {
    const min = 1;
    const max = 100000;
    const requestId = parseInt(min + Math.random() * (max - min), 10);
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      RequestId: requestId,
    });

    try {
      const response = await fetch(`${Constants.URL}chat_channel/${channelId}/messages/${requestId}`, {
        method: 'GET',
        headers: header,
      });
      const data = await Constants.handleErrors(response, dispatch, fetchNCRChatChannelMessagesError);
      dispatch(fetchNCRChatChannelMessagesSuccess(data.messages));
    } catch (error) {
      dispatch(fetchNCRChatChannelMessagesError(error));
    }
    return null;
  };
}

export function sendNCRChatMessage(payload, token) {
  return async dispatch => {
    const min = 1;
    const max = 100000;
    const requestId = parseInt(min + Math.random() * (max - min), 10);
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
      RequestId: requestId,
    });
    if (payload?.message.trim().length <= 0) {
      const error = new Error('Message is empty');
      dispatch(sendNCRChatMessageError(error));
      return null;
    }
    try {
      const response = await fetch(`${Constants.URL}chat_channel/${payload.chat_channel_id}/messages/${requestId}`, {
        method: 'POST',
        headers: header,
        body: JSON.stringify(payload),
      });
      await Constants.handleErrors(response, dispatch, sendNCRChatMessageError);
      dispatch(fetchNCRChatChannelMessages(payload.chat_channel_id, token));
    } catch (error) {
      dispatch(sendNCRChatMessageError(error));
    }
    return null;
  };
}

export function fetchNCRChatUnreadMessages(caseId, token) {
  return async dispatch => {
    dispatch(resetNCRChatUnreadMessages());
    const header = new Headers({
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    });

    try {
      const response = await fetch(`${Constants.URL}grinder_ncr/${caseId}/unseen?check_only=true`, {
        method: 'GET',
        headers: header,
      });
      const data = await Constants.handleErrors(response, dispatch, fetchNCRChatUnreadMessagesError);
      dispatch(fetchNCRChatUnreadMessagesSuccess(data));
    } catch (error) {
      dispatch(fetchNCRChatUnreadMessagesError(error));
    }
    return null;
  };
}
